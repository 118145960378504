import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from 'utils/api';
import DataTable from 'components/dataTable/DataTable';
import { ColumnDef } from '@tanstack/react-table';
import Pagination from 'components/pagination/Pagination';
import formatDate from 'utils/helpers/date';
import Drawer from 'components/drawer/Drawer';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import CheckInsForm from './CheckInsForm';
import { CheckInsType } from 'types/checkins';
import { Tooltip } from 'antd';
import Button from 'components/button/Button';

const getCheckIns = async (currentPage: number, id: string | undefined) => {
  const data = await request({
    url: '/check-ins',
    method: 'GET',
    params: { client_id: id, page: currentPage, per_page: 5 }
  });
  return data;
};

export default function CheckIns() {
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();

  const { data: CheckInsData, isLoading } = useQuery({
    queryKey: ['checkIns', id, currentPage],
    queryFn: () => getCheckIns(currentPage, id)
  });
  const checkInsMemorizedData = useMemo(() => {
    return CheckInsData?.check_ins ?? [];
  }, [CheckInsData?.check_ins]);

  return (
    <>
      <div className='border border-light-grey p-4 rounded-xl'>
        <div className='flex justify-between items-center mb-4'>
          <h4 className='text-xl font-semibold'>Check Ins</h4>
          <Drawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            button={
              <Button className='!px-3 text-sm !w-fit'>+ Add Check Ins</Button>
            }
            title='Add Check Ins'
            closeIcon={<ArrowRightOutlined />}
          >
            <CheckInsForm setIsClose='' />
          </Drawer>
        </div>
        <div className='overflow-auto h-[calc(100vh_-_390px)]'>
          <DataTable
            data={checkInsMemorizedData}
            columns={columns}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Pagination
        totalCount={CheckInsData?.pagination.total_count}
        totalPage={CheckInsData?.pagination.total_pages}
        perPage={CheckInsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

const columns: ColumnDef<CheckInsType>[] = [
  {
    header: 'Date',
    accessorKey: 'date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Weight(lbs)',
    accessorKey: 'weight'
  },
  {
    header: 'Satisfaction',
    accessorKey: 'satisfaction'
  },
  {
    header: 'Medication',
    accessorKey: 'medication',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Uses/Last Vial',
    accessorKey: 'last_vial_used',
    cell: (info) => (
      <p className='truncate max-w-24 text-orange'>
        <Tooltip title={info.getValue() as string} placement='left'>
          {info.getValue() as string}
        </Tooltip>
      </p>
    )
  },
  {
    header: 'Mg Used',
    accessorKey: 'mg_used'
  },
  {
    header: 'Mg Left',
    accessorKey: 'mg_left'
  },
  {
    header: 'Weeks Left',
    accessorKey: 'weeks_left'
  },
  {
    header: 'Adjusted Dose',
    accessorKey: 'adjusted_dose'
  },
  {
    header: 'Adjusted Unit',
    accessorKey: 'adjusted_units'
  },
  {
    header: 'Placed order Date',
    accessorKey: 'placed_order_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info) => (
      <>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Check Ins Details'
          closeIcon={<ArrowRightOutlined />}
        >
          <CheckInsForm info={info.row.original} setIsClose={''} />
        </Drawer>
      </>
    )
  }
];
