import React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import DataTableLoader from './DataTableLoader';
import NoData from 'assets/images/noData.svg';

interface DataTableProps<Data> {
  data?: Data[];
  columns: ColumnDef<Data>[];
  isLoading?: boolean;
  isHeaderSticky?: boolean;
  isVerticalBorderEnabled?: boolean;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
}

export default function DataTable<Data>({
  data = [],
  columns,
  isLoading = false,
  isHeaderSticky = true,
  isVerticalBorderEnabled = false,
  sorting,
  onSortingChange
}: DataTableProps<Data>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      sorting
    },
    onSortingChange
  });

  return (
    <table className='table-auto w-full'>
      <thead className={`${isHeaderSticky && 'sticky top-0 z-10'}`}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <th
                className={`bg-light-orange text-xs p-3 border-b border-[#D5D5D5] text-left font-semibold ${isVerticalBorderEnabled && table.getHeaderGroups()[0].headers.length !== index + 1 && 'border-r'}`}
                key={header.id}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {isLoading ? (
          <DataTableLoader
            colCount={table.getHeaderGroups()[0].headers.length}
          />
        ) : (
          <>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                className={`border-b border-[#D5D5D5] ${index === table.getRowModel().rows.length - 1 ? 'last:border-b-0' : ''}`}
                key={row.id}
              >
                {row.getVisibleCells().map((cell, subIndex) => (
                  <td
                    className={`p-3 text-sm ${isVerticalBorderEnabled && table.getHeaderGroups()[0].headers.length !== subIndex + 1 && 'border-r'}`}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
        {!isLoading && data.length === 0 && (
          <tr>
            <td
              className='text-center'
              colSpan={table.getHeaderGroups()[0].headers.length}
            >
              <div className='mt-10'>
                <img src={NoData} alt='no data' className='mx-auto' />
                <p className='mt-2 text-sm font-semibold text-gray-400'>
                  No Data
                </p>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
