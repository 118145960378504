import Tab from 'components/tab/Tab';
import { Outlet, useLocation } from 'react-router-dom';

const items = [
  {
    key: 'knowledge-base',
    label: 'Knowledge Base'
  },
  {
    key: 'retrieval-testing',
    label: 'Retrieval Testing'
  }
];

export default function ChatBotPage() {
  const { pathname } = useLocation();
  return (
    <>
      <div className='sticky top-[58px] z-50 bg-white'>
        <Tab
          items={items}
          defaultActiveKey='knowledge_base'
          activeKey={pathname.split('/')[2]}
        />
      </div>
      <Outlet />
    </>
  );
}
