import { Select as SelectComponent, SelectProps } from 'antd';

export default function Select({
  options,
  placeholder,
  ...props
}: SelectProps) {
  return (
    <>
      <SelectComponent placeholder={placeholder} options={options} {...props} />
    </>
  );
}
