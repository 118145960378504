import React from 'react';
import logo from 'assets/images/logo.svg';
import { Avatar, Dropdown, MenuProps, Space } from 'antd';
import paths from 'constants/path';
import NavLinkItem from './components/NavLinkItem';
import { Link } from 'react-router-dom';
import { getCookie, removeCookie } from 'utils/cookies';
import { ArrowLeftOutlined } from '@ant-design/icons';

const items: MenuProps['items'] = [
  {
    label: (
      <Link to='/login' className='p-2 !text-orange font-semibold'>
        <ArrowLeftOutlined className='mr-2' />
        Log Out
      </Link>
    ),
    key: '0'
  }
];

export default function Header() {
  return (
    <div className='bg-dark-blue sticky top-0 z-50'>
      <div className='py-2 px-8 flex justify-between items-center'>
        <Link to='/'>
          <img src={logo} alt='Logo' className='w-[110px] h-[42px]' />
        </Link>
        <div className='flex gap-10'>
          <NavLinkItem path={paths.customers}>Customer</NavLinkItem>
          <NavLinkItem path={paths.orders}>Orders</NavLinkItem>
          <NavLinkItem path={paths.checkins}>CheckIns</NavLinkItem>
          <NavLinkItem path={paths.chatbot}>ChatBot</NavLinkItem>
          <NavLinkItem path={paths.smsLogs}>Sms Logs</NavLinkItem>
          <NavLinkItem path={paths.reports}>Reports</NavLinkItem>
          <NavLinkItem path={paths.settings}>Settings</NavLinkItem>
        </div>
        <div className='flex items-center gap-2 cursor-pointer'>
          <Dropdown menu={{ items }} trigger={['click']}>
            <p onClick={() => removeCookie('token')}>
              <Space>
                <Avatar src='https://api.dicebear.com/7.x/miniavs/svg?seed=1' />
                <div>
                  <p className='text-orange text-sm font-medium'>
                    {getCookie('fullName')}
                  </p>
                  <p className='text-xs text-white'>Admin</p>
                </div>
              </Space>
            </p>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
