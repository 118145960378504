import PrivateRoute from 'components/appGuards/PrivateRoutes';
import { Home } from 'pages/Home';
import Login from 'pages/auth/Login';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import paths from './path';
import CustomerPage from 'pages/customer/CustomerPage';
import CustomersPage from 'pages/customers/CustomersPage';
import ChatBotPage from 'pages/chatbot/ChatbotPage';
import KnowledgeBasePage from 'pages/knowledgeBase/KnowledgeBasePage';
import KnowledgeBaseForm from 'pages/knowledgeBase/components/KnowledgeBaseForm';
import IntentsPage from 'pages/intents/IntentsPage';
import KnowledgeBaseApprovalsPage from 'pages/knowledgeBaseApprovals/KnowledgeBaseApprovalsPage';
import OrdersPage from 'pages/orders/Orders';
import RetrievalTesting from 'pages/retrievalTesting/RetrievalTesting';
import CheckInsPage from 'pages/checkins/CheckIns';
import SmsLogsPage from 'pages/smsLogs/SmsLogsPage';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace={true} to={paths.customers} />
      },
      { path: paths.customers, element: <CustomersPage /> },
      { path: paths.orders, element: <OrdersPage /> },
      { path: paths.checkins, element: <CheckInsPage /> },
      { path: paths.customer(), element: <CustomerPage /> },
      { path: paths.smsLogs, element: <SmsLogsPage /> },
      {
        path: paths.chatbot,
        element: <ChatBotPage />,
        children: [
          {
            index: true,
            element: <Navigate replace={true} to={paths.knowledgeBase} />
          },
          { path: paths.knowledgeBase, element: <KnowledgeBasePage /> },
          { path: paths.retrievalTesting, element: <RetrievalTesting /> },
          { path: paths.addKnowledgeBase, element: <KnowledgeBaseForm /> },
          { path: paths.editKnowledgeBase(), element: <KnowledgeBaseForm /> },
          { path: paths.intents, element: <IntentsPage /> },
          {
            path: paths.knowledgeBaseApprovals,
            element: <KnowledgeBaseApprovalsPage />
          }
        ]
      },
      {
        path: paths.reports,
        element: (
          <h1 className='font-bold text-center text-silver text-3xl mt-10'>
            Coming Soon...{' '}
          </h1>
        )
      },
      {
        path: paths.settings,
        element: (
          <h1 className='font-bold text-center text-silver text-3xl mt-10'>
            Coming Soon...
          </h1>
        )
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  }
]);
